<template>
    <ul class="template_list">
        <li>
            <div class="card_item active" @click="selectTemplate($event, 'email')">
                <img src="@/assets/images/mail.svg">Email
            </div>
        </li>
        <!-- <li>
            <div class="card_item" @click="selectTemplate($event, 'content')">
                <img src="@/assets/images/mail.svg">Content
            </div>
        </li> -->
        <li>
            <div class="card_item" @click="selectTemplate($event, 'sms')">
                <img src="@/assets/images/sms.svg">SMS
            </div>
        </li>
        <li>
            <div class="card_item" @click="selectTemplate($event, 'voice')">
                <img src="@/assets/images/playbook.svg">Voice
            </div>
        </li>
        <li>
            <div class="card_item" @click="selectTemplate($event, 'email,sms')">
                <img src="@/assets/images/template.svg">Multi-Channel
            </div>
        </li>
    </ul>
    <div class="form_grp">
        <div class="group_item">
            <label class="input_label">Category</label>
            <div class="field_wpr">
                <multiselect
                    v-model="category"
                    :options="categories"
                    value-prop="code"
                    label="type"
                    :searchable="true"
                    placeholder="Select category"
                ></multiselect>
            </div>
        </div>
    </div>
    <div v-show="category" class="tempcard">
        <ul v-if="templates.length && !templateAjaxLoader">
            <li v-for="(template, t) of templates" :key="t">
                <label :for="`tempcard-${t}`" class="checkbox">
                    <input type="checkbox" :id="`tempcard-${t}`" v-model="form.template_id" :value="template.id" hidden>
                    <span><i class="fas fa-check"></i></span>
                </label>
                <div class="tempcard_details">
                    <h5>{{ template.title }}</h5>
                    <p>
                        <i v-if="template.is_email === 1" class="fas fa-envelope"></i>
                        <i v-if="template.is_sms === 1" class="fas fa-comment"></i>
                        <i v-if="template.is_phone === 1" class="fas fa-mobile"></i>
                        <i v-if="template.is_content === 1" class="fas fa-file"></i>
                        <span v-if="template.is_email === 1">{{ stripTagsAndEllipsis(template.subject) }}</span>
                        <span v-if="template.is_sms === 1">{{ stripTagsAndEllipsis(template.sms_content) }}</span>
                        <span v-if="template.is_content === 1">{{ stripTagsAndEllipsis(template.content) }}</span>
                    </p>
                </div>
                <span class="view" @click="previewTemplate(template)">
                    <i class="fas fa-search-plus"></i>
                </span>
            </li>
        </ul>
        <div class="text-center" v-else>
            <p v-if="templateAjaxLoader">Loading Templates, please wait...</p>
            <p v-else>No template found for this category</p>
        </div>
    </div>
    <div v-if="view" class="modal preview_modal right_out" @click.self="view = false">
        <div class="modal_container p-0">
            <div class="modal_header">
                <div class="modal_header border-bottom px-5">
                    <h1>Preview Template</h1>
                    <button type="button" class="btn cancel_btn" @click="view = false"><i class="fas fa-times"></i></button>
                </div>                
            </div>
            <div class="modal_body">
                <div class="preview-content m-0" v-if="selectedTemplate.is_email">
                    <b>Subject:</b> {{ selectedTemplate.subject }} <br /> <br />
                    <b>Email Content:</b> <span v-html="selectedTemplate.email_content"></span>
                </div>

                <div class="preview-content" v-if="selectedTemplate.is_sms" v-html="selectedTemplate.sms_content"></div>
                <div class="preview-content" v-if="selectedTemplate.is_content" v-html="selectedTemplate.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Template Component',

        data () {
            return {
                category: '',
                type: 'email',
                form: {
                    template_id: [],
                    contact_id: '',
                },
                view: false,
                selectedTemplate: {}
            };
        },

        props: {
            contacts: [String, Number],
            modelValue: [Object, Array],
        },

        emits: ['update:modelValue'],

        components: {
            Multiselect,
        },

        watch: {
            category (category) {
                const vm = this;

                vm.getTemplates({ category, type: vm.type});
            },

            'form.template_id' (ids) {
                const vm = this;

                vm.$emit('update:modelValue', ids);
            },
        },

        computed: mapState({
            categories: state => state.templateModule.categories,
            templates: state => state.templateModule.templates,
            templateAjaxLoader: state => state.templateModule.templateAjaxLoader,
        }),

        mounted () {
            const vm = this;

            if (vm.categories.length == 0) {
                vm.getCategories();
            }
        },

        methods: {
            ...mapActions({
                getCategories: 'templateModule/getCategories',
                getTemplates: 'templateModule/getTemplates',
                sendTemplate: 'templateModule/sendTemplate',
            }),

            selectTemplate (e, type) {
                const vm = this;
                let temp = e.currentTarget;
                let allTemp = temp.closest('.template_list').querySelectorAll('.card_item');

                vm.type = type;

                for (let i = 0; i < allTemp.length; i++) {
                    allTemp[i].classList.remove('active');
                }

                if (temp.classList.contains('active')) {
                    temp.classList.remove('active');
                } else {
                    temp.classList.add('active');
                }

                if (vm.category) {
                    vm.getTemplates({ category: vm.category, type });
                }
            },

            stripTagsAndEllipsis (string, length = 35) {
                let newString = string.replace(/(<([^>]+)>)/gi, '');

                if (newString.length > length) {
                    newString = newString.substring(0, length) + '...';
                }

                return newString;
            },

            handleTemplateSend () {
                const vm = this;
                vm.form.contact_id = vm.contacts;

                return vm.sendTemplate(vm.form);
            },

            previewTemplate (template) {
                const vm = this;

                vm.selectedTemplate = template;
                vm.view = true;
            },
        },
    }
</script>

<style scoped>
    .modal_body {
        min-height: 200px;
        max-height: 600px;
        padding: 30px;
    }
    .modal_header{
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
    }
    .cancel_btn {
        border: 0;
        background: rgba(50,55,59,.6);
        font-size: 12px;
        color: #fff;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        min-width: 20px;
    }
    .preview-content {
        height: 360px;
        overflow-y: scroll;
        padding: 20px;
        flex: 0 1 640px;
        margin: 50px auto;
        border: 1px solid rgb(221, 221, 221);
        background-color: rgb(255, 255, 255);
    }
    .preview-content::-webkit-scrollbar {
        width: 4px;
    }
    .preview-content:hover::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
        border-radius: 2px;
    }
</style>